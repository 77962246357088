'use strict';

$(function() {
  // THEME COLORS
  var style = getComputedStyle(document.body);
  var chartColors = ["#696ffb", "#7db8f9", "#05478f", "#00cccc", "#6CA5E0", "#1A76CA"];
  var primaryColor = style.getPropertyValue('--primary');
  var secondaryColor = style.getPropertyValue('--secondary');
  var successColor = style.getPropertyValue('--success');
  var warningColor = style.getPropertyValue('--warning');
  var dangerColor = style.getPropertyValue('--danger');
  var infoColor = style.getPropertyValue('--info');
  var darkColor = style.getPropertyValue('--dark');


  // BODY ELEMENTS
  var Body = $("body");
  var TemplateSidebar = $('.sidebar');
  var TemplateHeader = $('.t-header');
  var PageContentWrapper = $(".page-content-wrapper");
  var DesktopToggler = $(".t-header-desk-toggler");
  var MobileToggler = $(".t-header-mobile-toggler");

  // SIDEBAR TOGGLE FUNCTION FOR MOBILE (SCREEN "MD" AND DOWN)
  MobileToggler.on("click", function () {
    $(".page-body").toggleClass("sidebar-collpased");
  });

});
