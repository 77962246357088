import $ from "jquery";

function setHighContrast() {

    $(document).off('click.high-contrast-toggle')
    $(document).on('click.high-contrast-toggle', '.high-contrast-toggle', function () {
        const $body = $('body');

        $body.toggleClass('high-contrast');

        let onOff = "off";
        if ($body.hasClass('high-contrast')) {
            onOff = "on"
        }
        document.cookie = `high-contrast=${onOff}; path=/; max-age=3600`
    })

    if (document.cookie.match(/high-contrast=on/)) {
        const $body = $('body');
        $body.addClass('high-contrast');
    } else {
        const $body = $('body');
        $body.removeClass('high-contrast');
    }
}


document.addEventListener('turbolinks:load', setHighContrast);
$(setHighContrast);
